var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "eventForm", attrs: { name: "eventForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title:
                  "" +
                  _vm.$t("Dados do evento") +
                  (_vm.event.id ? " #" + _vm.event.id : ""),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "store_id",
                          required: true,
                          clearable: false,
                          disabled: _vm.isReadOnly || _vm.isEdit,
                        },
                        on: { input: _vm.onSelectStore },
                        model: {
                          value: _vm.event.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.event, "storeId", $$v)
                          },
                          expression: "event.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "event_name",
                          type: _vm.isReadOnly ? "label" : "text",
                          label: _vm.$t("Nome do evento"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.event.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.event, "name", $$v)
                          },
                          expression: "event.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "price_table",
                          type: _vm.isReadOnly ? "label" : "vue-select",
                          label: _vm.$t("Tabela de preço"),
                          options: _vm.getComboPriceTables,
                          validation: "required",
                        },
                        model: {
                          value: _vm.event.priceTableId,
                          callback: function ($$v) {
                            _vm.$set(_vm.event, "priceTableId", $$v)
                          },
                          expression: "event.priceTableId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading || _vm.loadingProducts,
                "action-collapse": "",
                title: _vm.$t("Produtos"),
              },
            },
            [
              _c(
                "b-row",
                [
                  !_vm.isReadOnly
                    ? _c(
                        "b-col",
                        {
                          staticClass: "mb-1 d-flex justify-content-end",
                          attrs: { md: "12" },
                        },
                        [
                          _c("e-button", {
                            attrs: {
                              text: _vm.$t("Adicionar produto"),
                              variant: "primary",
                            },
                            on: {
                              click: function () {
                                return _vm.showProductSidebar()
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-table", {
                        staticClass: "bordered",
                        attrs: {
                          "show-empty": "",
                          responsive: "",
                          striped: "",
                          "empty-text": _vm.$t("Nenhum produto adicionado"),
                          fields: _vm.skusFields,
                          items: _vm.getSkus,
                          "details-td-class": "py-0 px-1",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": true,
                                    "show-delete": true,
                                  },
                                  on: {
                                    update: function ($event) {
                                      return _vm.showProductSidebar(row.item)
                                    },
                                    delete: function ($event) {
                                      return _vm.onRemoveProduct(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading || _vm.loadingProducts,
                "action-collapse": "",
                title: _vm.$t("Banners"),
              },
            },
            [
              _c(
                "b-row",
                [
                  !_vm.isReadOnly
                    ? _c(
                        "b-col",
                        {
                          staticClass: "mb-1 d-flex justify-content-end",
                          attrs: { md: "12" },
                        },
                        [
                          _c("e-button", {
                            attrs: {
                              text: _vm.$t("Adicionar banner"),
                              variant: "primary",
                            },
                            on: {
                              click: function () {
                                return _vm.showBannerSidebar()
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-table", {
                        staticClass: "bordered",
                        attrs: {
                          "show-empty": "",
                          responsive: "",
                          striped: "",
                          "empty-text": _vm.$t("Nenhum banner adicionado"),
                          fields: _vm.bannersFields,
                          items: _vm.getEventBannersAtm,
                          "details-td-class": "py-0 px-1",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": true,
                                    "show-delete": true,
                                  },
                                  on: {
                                    update: function ($event) {
                                      return _vm.showBannerSidebar(row.item)
                                    },
                                    delete: function ($event) {
                                      return _vm.onRemoveBannerAtm(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(image)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("b-img-lazy", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    "max-height": "150px",
                                  },
                                  attrs: {
                                    src:
                                      item.image ||
                                      (item.localImage &&
                                        item.localImage.urlResized),
                                    rounded: "",
                                    center: "",
                                    width: "165px",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "pb-1" }),
      _c("event-banner-sidebar", {
        ref: "eventBannersRef",
        attrs: { event: _vm.event },
        on: { add: _vm.onAddBannerAtm, update: _vm.onUpdateBannerAtm },
      }),
      _vm.event.storeId
        ? _c("event-product-sidebar", {
            ref: "productSidebar",
            attrs: {
              "store-id": _vm.event.storeId,
              "price-table-id": _vm.event.priceTableId,
            },
            on: { update: _vm.onUpdateProduct, add: _vm.onAddProduct },
          })
        : _vm._e(),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
          busy: _vm.loading,
        },
        on: {
          save: _vm.saveEvent,
          "cancel-event": _vm.onCancel,
          back: _vm.back,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }