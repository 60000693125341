var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-event-banner",
      title: _vm.isEdit ? _vm.$t("Editar Banner") : _vm.$t("Adicionar Banner"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "600px",
    },
    on: { save: _vm.onSaveBanner, hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "bannerSidebar", attrs: { name: "bannerSidebar" } },
              [
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      "show-loading": _vm.fetching || _vm.saving,
                      "action-collapse": "",
                      title: _vm.$t("Banners do evento"),
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "banner-sequence",
                                type: "number",
                                validation: "optional|max:999|min:-999",
                                label: _vm.$t("Ordem"),
                              },
                              model: {
                                value: _vm.bannerData.sequence,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bannerData, "sequence", $$v)
                                },
                                expression: "bannerData.sequence",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "banner-image-local",
                                type: "uploader",
                                deletable: "true",
                                "max-size": "500KB",
                                meta: false,
                                "max-files": 1,
                                label: _vm.$t("Banner"),
                              },
                              model: {
                                value: _vm.imagesLocal,
                                callback: function ($$v) {
                                  _vm.imagesLocal = $$v
                                },
                                expression: "imagesLocal",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }