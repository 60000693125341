<template>
  <e-sidebar
    id="sidebar-form-event-banner"
    :title="isEdit ? $t('Editar Banner') : $t('Adicionar Banner')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveBanner"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="bannerSidebar"
        name="bannerSidebar"
      >
        <b-card-actions
          :show-loading="fetching || saving"
          action-collapse
          :title="$t('Banners do evento')"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="banner-sequence"
                v-model="bannerData.sequence"
                type="number"
                validation="optional|max:999|min:-999"
                :label="$t('Ordem')"
              />
            </b-col>
            <b-col md="12">
              <FormulateInput
                id="banner-image-local"
                v-model="imagesLocal"
                type="uploader"
                deletable="true"
                :max-size="'500KB'"
                :meta="false"
                :max-files="1"
                :label="$t('Banner')"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { uploader } from '@/mixins'
import ESidebar from '@/views/components/ESidebar.vue'

const getInitialBannerData = () => ({
  id: undefined,
  sequence: undefined,
  image: undefined,
  localImage: undefined,
})

export default {
  name: "EventBannerSidebar",
  components: {
    BRow,
    BCol,
    BCardActions,
    ESidebar,
  },

  mixins: [uploader],

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showSidebar: false,
      saving: false,
      fetching: false,
      isEdit: false,
      imagesLocal: [],
      bannerData: getInitialBannerData()
    }
  },

  computed: {

  },

  mounted() { },

  methods: {
    async show(banner) {
      this.cleanSidebar()
      this.showSidebar = true

      if (banner) {
        this.isEdit = true

        this.bannerData = { ...getInitialBannerData(), ...banner }
        this.imagesLocal = [this.getFileInstance(banner.image)]
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.isEdit = false
      this.imagesLocal = []
      this.bannerData = getInitialBannerData()
    },

    async onSaveBanner() {
      this.$refs.bannerSidebar.showErrors()
      if (this.$refs.bannerSidebar.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      // valida tamanho da imagem maior que 500KB (size está em byte)
      if (this.imagesLocal?.length > 0 && this.imagesLocal[0].size > 512000) {
        this.showInvalidDataMessage({ message: 'Tamanho do arquivo da imagem deve ser menor que 500KB' })
        return
      }

      try {
        this.saving = true

        if (this.event?.id) {
          const { uploadedList, localImage } = await this.bannersUpload(this.imagesLocal[0])
          const [bannerUploaded] = uploadedList
          if (localImage) {
            this.imagesLocal = [localImage]
          }

          this.bannerData.image = bannerUploaded?.url

          const { data } = await this.$http.post(`/api/sales/events/${this.event.id}/banner-atm`, {
            ...this.bannerData
          })

          this.bannerData.id = data
          this.bannerData.localId = undefined

          this.showSuccess({
            message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
        } else {
          const [image] = this.imagesLocal
          this.bannerData.localImage = image
        }

        if (this.isEdit) {
          this.$emit('update', this.bannerData)
        } else {
          this.$emit('add', this.bannerData)
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async bannersUpload(localImg) {
      const uploadedList = await this.upload({
        files: [localImg],
        destinationPath: `images/bannersAtm/events/Id=${this.event.id}`,
      })

      let localImage
      if (uploadedList?.length) {
        localImage = this.getFileInstance(uploadedList[0].url)
      }

      return { uploadedList, localImage }
    }
  }
}
</script>
